import React from 'react'
import { graphql } from 'gatsby'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import * as Accessa from 'accessa'
import Image from 'gatsby-image'
import {
  Box,
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core'
import {
  Body,
  FunFacts,
  Hero,
  withGlobals,
} from '../components'
import { getAsArray } from '../utils/helpers'

const useStyles = makeStyles(() => ({
  cardImage: {
    height: '300px',
  },
}))

const Page = ({ contentfulPage }) => {
  const cls = useStyles()
  return (
    <>
      <Hero {...contentfulPage} />
      <Body {...contentfulPage} />
      <Box style={{ backgroundColor: 'rgb(250,250,250)' }}>
        {getAsArray(contentfulPage, 'panels').map(
          (attribute, i) => {
            const lists = getAsArray(attribute, 'nested')

            return lists !== null ? (
              <Box pb={4} key={`attribute${i}`}>
                <Box>
                  <Box
                    aria-hidden
                    height="525px"
                    position="relative"
                    style={{
                      margin:
                        i % 2 ? '0 0 0 auto' : '0 auto 0 0',
                    }}
                    width="90%"
                  >
                    <Image
                      {...get(attribute, 'image')}
                      imgStyle={{
                        objectPosition: 'top',
                      }}
                      style={{
                        height: '100%',
                        width: '100%',
                      }}
                    />
                  </Box>
                </Box>
                <Box mt={-20} position="relative" p={1}>
                  <Container
                    style={{ backgroundColor: '#FFF' }}
                  >
                    <Accessa.Sections.Block>
                      <Typography variant="h3">
                        {attribute.title}
                      </Typography>
                      <Accessa.Lists.Elements
                        title={get(attribute, 'title')}
                        lists={lists.map((item) => ({
                          src: get(item, 'icon.fluid.src'),
                          alt: get(item, 'icon.title'),
                          subtitle2: get(item, 'title'),
                          body: get(
                            item,
                            'description.description'
                          ),
                        }))}
                      />
                    </Accessa.Sections.Block>
                  </Container>
                </Box>
              </Box>
            ) : null
          }
        )}
      </Box>
      <FunFacts
        {...contentfulPage}
        darken={false}
        component={({ children }) => (
          <Box mb={4} py={8}>
            {children}
          </Box>
        )}
      >
        <Container maxWidth="lg">
          <Box mt={4}>
            <Accessa.Features.Field
              lg={3}
              md={4}
              sm={6}
              xs={6}
              spacing={1}
              gatsbyImageClass={cls.cardImage}
              lists={getAsArray(
                contentfulPage,
                'employees'
              ).map((item) => ({
                title: get(item, 'name'),
                description: get(item, 'role'),
                fluid: get(item, 'image.fluid'),
                alt: `Headshot of CRG employee ${get(
                  item,
                  'name'
                )}`,
              }))}
            />
          </Box>
        </Container>
      </FunFacts>
    </>
  )
}

Page.propTypes = {
  // eslint-disable-next-line
  contentfulPage: PropTypes.object.isRequired,
}

export const query = graphql`
  query getContentfulPageById(
    $contentful_id: String
    $node_locale: String
  ) {
    contentfulPage(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      title
      node_locale
      seo {
        title
        description {
          description
        }
      }
      hero {
        title
        image {
          fluid {
            src
          }
        }
      }
      content {
        json
      }
      funFacts {
        title
        description
        colour
        graphic {
          fluid {
            src
          }
        }
      }
      callToAction {
        title
        description {
          description
        }
      }
      employees {
        name
        role
        image {
          fluid {
            src
          }
        }
      }
      panels {
        title
        image {
          fluid {
            src
          }
        }
        nested {
          ... on ContentfulAttributes {
            title
            description {
              description
            }
            icon {
              title
              fluid {
                src
              }
            }
          }
        }
      }
    }

    hreflang: allContentfulPage(
      filter: {
        contentful_id: { eq: $contentful_id }
        node_locale: { ne: $node_locale }
      }
    ) {
      nodes {
        node_locale
        relativePath
      }
    }
  }
`

export default withGlobals(Page)
